export class Car {
    idvehicule: number;
    image1: string;
    image2: string;
    image3: string;
    image4: string;
    marque: string;
    anneeprod: number;
    typecarburant: string;
    nbvu: string;
    nbplacedispo: number;
    motorisation: string;
    numimmatriculation: string;
    previmmatriculation: string;
    validfrom: string;
    validto: string;
    nomvehicule: string;
    ssdtid: string;
    ville: string;
    genrevehicule: string;
    typevehicule: string;
    carosserie: string;
    numenregistrement: number;
    numchassis: number;
    cylindre: number;
    nbchevaux: number;
    poidstotalcharge: number;
    poidsvide: number;
    datedelivrance: string;
    ssdt: string;
    suspendu: boolean;
    vendu: boolean;
    archiver: boolean;
    position: number;

    prixvente?: number;
    prixlocation?: number;
    prixcourvoirturage?: number;
    prixlocationhs?: number;
}
