import { Component, Input, AfterViewInit, HostBinding, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/service/auth.service';
import { AppService } from '../../app.service';
import { LayoutService } from '../layout.service';

@Component({
  selector: 'app-layout-sidenav',
  templateUrl: './layout-sidenav.component.html',
  styles: [':host { display: block; }']
})
export class LayoutSidenavComponent implements OnInit, AfterViewInit {
  @Input() orientation = 'vertical';

  immo = false;
  auto = false;
  super = false;
  agence = false;
  style = false;
  formation = false;
  resto = false;
  bien = false;
  evt = false;
  vente = false;

  @HostBinding('class.layout-sidenav') hostClassVertical = false;
  @HostBinding('class.layout-sidenav-horizontal') hostClassHorizontal = false;
  @HostBinding('class.flex-grow-0') hostClassFlex = false;

  constructor(private router: Router, private appService: AppService, private layoutService: LayoutService, private authService: AuthService) {
    // Set host classes
    this.hostClassVertical = this.orientation !== 'horizontal';
    this.hostClassHorizontal = !this.hostClassVertical;
    this.hostClassFlex = this.hostClassHorizontal;
  }
  ngOnInit(): void {
    const privs = this.authService.getPriviledges().split(',');
    // console.log("Privileges", privs);
    for (const pr of privs) {
      if(pr === 'ROLE_SUPER'){
        this.immo = true;
        this.auto = true;
        this.super = true;
        this.agence = true;
        this.style = true;
        this.formation = true;
        this.resto = true;
        this.bien = true;
        this.evt = true;
        this.vente = true;
        break;
      }
      if(pr === 'ROLE_VEHI') {
        this.auto = true;
        continue;
      }
      if(pr === 'ROLE_IMMO') {
        this.immo = true;
        continue;
      }
      if(pr === 'ROLE_FORMA') {
        this.formation = true;
        continue;
      }
      if(pr === 'ROLE_VOYAGE') {
        this.agence = true;
        continue;
      }
      if(pr === 'ROLE_STYHAUT') {
        this.style = true;
        continue;
      }
      if(pr === 'ROLE_TRAITE') {
        this.resto = true;
        continue;
      }
      if(pr === 'ROLE_BIENETR') {
        this.bien = true;
        continue;
      }
      if(pr === 'ROLE_EVNT') {
        this.evt = true;
        continue;
      }
      if(pr === 'ROLE_VENTE') {
        this.vente = true;
        continue;
      }
    }
  }

  ngAfterViewInit() {
    // Safari bugfix
    this.layoutService._redrawLayoutSidenav();
  }

  getClasses() {
    let bg = this.appService.layoutSidenavBg;

    if (this.orientation === 'horizontal' && (bg.indexOf(' sidenav-dark') !== -1 || bg.indexOf(' sidenav-light') !== -1)) {
      bg = bg
        .replace(' sidenav-dark', '')
        .replace(' sidenav-light', '')
        .replace('-darker', '')
        .replace('-dark', '');
    }

    return `${this.orientation === 'horizontal' ? 'container-p-x ' : ''} bg-${bg}`;
  }

  isActive(url) {
    return this.router.url.startsWith(url);
    /*return this.router.isActive(url, true);*/
  }

  isMenuActive(url) {
    return this.router.url.startsWith(url);
    /*return this.router.isActive(url, false);*/
  }

  isMenuOpen(url) {
    return this.router.isActive(url, false) && this.orientation !== 'horizontal';
  }

  toggleSidenav() {
    this.layoutService.toggleCollapsed();
  }
}
