import { Injectable } from '@angular/core';
import {environment} from '../../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Car} from '../model/car';
import {User} from "../../../../auth/model/user";

@Injectable({
  providedIn: 'root'
})
export class CarService {
  private baseUrl = environment.BASE_URL + 'admin/';

  constructor(private http: HttpClient) { }

  getList(): Observable<Array<Car>> {
    return this.http.get<Array<Car>>(this.baseUrl + 'listvehicules');
  }

  getById(id: number): Observable<Car> {
    return this.http.get<Car>(this.baseUrl + 'getvehicule/' + id);
  }

  create(category: Car): Observable<Car> {
    return this.http.post<Car>(this.baseUrl + 'addvehicule', category);
  }

  update(id: number, category: Car): Observable<Car> {
    return this.http.post<Car>(this.baseUrl + 'addvehicule', category);
  }

  uploadImage(image): Observable<any> {
    return this.http.post(this.baseUrl + 'uploadimage', image);
  }

  delete(car: Car): Observable<boolean> {
    return this.http.post<boolean>(this.baseUrl + 'delvehicule', car);
  }
}
