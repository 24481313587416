import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ToastrService } from 'ngx-toastr';
import { AppService } from 'src/app/app.service';
import { PaymentService } from 'src/app/feature/dashboard/payment/service/payment.service';
import { Payment } from '../../../../feature/dashboard/payment/model/payment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-orange-payment',
  templateUrl: './orange-payment.component.html',
  styles: [
  ]
})
export class OrangePaymentComponent implements OnInit {
  @Input() command: any;
  @Output() closeMode = new EventEmitter<boolean>();
  @Input() mode: Payment;
  @BlockUI('paymentCreateUIBlock') blockUIElement: NgBlockUI;
  omForm: FormGroup = new FormGroup({
    phone: new FormControl('', Validators.required)
  })
  constructor(private appService: AppService, public modal: NgbModal, private toast: ToastrService, private payService: PaymentService) {
    this.appService.pageTitle = 'Paiement de Facture';
  }

  ngOnInit(): void {
    console.log("Here the on init");
  }

  closeAndCancel() {
    this.closeMode.emit(true);
  }

  startPay() {
    if (this.omForm.invalid) {
      this.toast.warning("Veuillez remplir tous les champs", "Attention");
    } else {
      const phone = this.omForm.value.phone.toString();
      // const phone = 'sas';
      const second = phone.substring(0, 2);
      if (isNaN(Number(phone))) {
        this.toast.warning("Veuillez saisir un numéro de téléphone correct !", "Attention");
      } else {
        if (phone.length != 9) {
          this.toast.warning("Le numéro de téléphone doit contenir au plus 9 chiffres !", "Attention");
        } else if (second == '69') {
          this.submit(phone);
        } else if (second == '65') {
          if (parseInt(phone.charAt(2)) < 5) {
            this.toast.warning("Saisir un numéro Orange Cameroun correct !", "Attention");
          } else {
            this.submit(phone);
          }
        } else {
          this.toast.warning("Saisir un numéro Orange Cameroun correct !", "Attention");
        }
      }
    }
  }

  submit(phone) {
    const tel = parseInt(phone);
    const idCmd = this.command.idcommande;
    const modeId = this.mode.idmodepaiement;
    const serialCmdId = Buffer.from(idCmd.toString(),'utf-8').toString('base64');

    const url = `${location.protocol}//${location.host}/check-status/${serialCmdId}`;
    // Call the payment method
    this.toast.info('Début des opérations !', 'Information');
    this.blockUIElement.start();
    this.payService.assignPaymentMode(idCmd, modeId).subscribe(
      res => {
        this.payService.payWithMomoOrOM(idCmd, tel, url).subscribe(
          data => {
            this.toast.success("Processus de paiement initié avec succès !");
            this.toast.info("Vous allez être rediriger vers le site du payeur!", "Information");
            // this.modal.dismissAll();
            localStorage.setItem('cmdOM', idCmd.toString());
            setInterval(() => {
              this.goToSite(data);
            }, 5000);
            // this.getTheStatus();
          }, (error: HttpErrorResponse) => {
            console.log("Error", error);
            if (error.status === 200) {
              this.toast.success("Processus de paiement initié avec succès !");
              this.toast.info("Vous allez être rediriger vers le site du payeur!", "Information");
              // this.modal.dismissAll();
              localStorage.setItem('cmdOM', idCmd.toString());
              setTimeout(() => {
                this.goToSite(error?.error?.text);
              }, 5000);
            } else {
              this.toast.error("paiement impossible", "Erreur");
              this.toast.error(`Message: ${error.message}`, 'Erreur');
              this.blockUIElement.stop();
            }
          }
        )
      }, (error: HttpErrorResponse) => {
        this.toast.error("Echec d'initiation de paiement", "Erreur");
        this.toast.error(`Message: ${error.message}`, 'Erreur');
        this.blockUIElement.stop();
      }
    )
  }

  goToSite(data) {
    // let refLink = window.open(data, '_blank');
    location.href = data;
    // if (!refLink) {
    //   Swal.fire({
    //     title: 'Autorisation requise',
    //     text: "Veuillez autoriser l'ouverture des boîtes de dialogues dans votre navigateur",
    //     timer: 7000
    //   });
    //   this.modal.dismissAll();
    // }

    // setTimeout(() => {
    //   Swal.fire({
    //     title: 'Raffraichir vos données',
    //     text: 'Vous avez effectué le paiement ? Cliquez ici pour raffraîchir vos données.',
    //     icon: 'info',
    //     showCancelButton: true,
    //     cancelButtonText: "J'ai eu un problème",
    //     backdrop: false
    //   }).then(
    //     result => {
    //       if(result.isConfirmed) {
    //         this.toast.info(`Opération en cours...`, "Veuillez patienter");
    //         this.payService.getOMStatus(this.command.idcommande).subscribe(
    //           res => {
    //             console.log("Status", res);
    //             setTimeout(() => {
    //               this.toast.info(`Finalisation du processus`, "Veuillez patienter");
    //             }, 2000);
    //             location.reload();       
		//   }, (error: HttpErrorResponse) => {
    //             this.toast.warning("Erreur survenue", "Attention");
    //             location.reload();
    //           }
    //         )
    //       }else {
    //         setTimeout(() => {
    //           this.toast.info(`Veuillez reprendre le processus...`, "Information");
    //         }, 2000);
    //         location.reload();
    //       }
    //     }
    //   )
    // }, 8000);
  }
}
