<ng-template #serviceBlockTemplate>
    <div class="block-ui-template">
        <div class="sk-wave sk-primary mx-auto">
            <div class="sk-wave-rect"></div>
            <div class="sk-wave-rect"></div>
            <div class="sk-wave-rect"></div>
            <div class="sk-wave-rect"></div>
            <div class="sk-wave-rect"></div>
        </div>
    </div>
</ng-template>
<section class="container-fluid block-ui-white" *blockUI="'serviceCreateUIBlock'; template: serviceBlockTemplate">
        <div class="d-flex justify-content-between">
            <h4 class="media align-items-center font-weight-bold py-3 mb-4">
                <img src="" alt="" class="ui-w-50 rounded-circle">
                <div class="media-body ml-3">
                    Paiement de la commande #{{ command?.idcommande }}
                    <div class="text-muted text-tiny mt-1"><small class="font-weight-normal">Prix total <small class="text-primary">{{ command?.prixtotal|number }} XAF</small></small></div>
                </div>
            </h4>
            <p class="py-3">
                <a href="javascript:void(0);" (click)="modal.dismissAll()" class="btn btn-sm btn-outline-secondary">Fermer <i class="bx bx-home"></i></a>
            </p>
        </div>
        
        <hr class="container-m-nx mt-0 mb-4">

        <div class="row  block-ui-white" *blockUI="'payCreateUIBlock'; template: payBlockTemplate">
            <div class="d-flex col-12 align-items-stretch">
                <!-- Stats + Links -->
                <div class="card d-flex w-100 mb-4">
                    <div class="row no-gutters row-bordered h-100">
                        <div class="d-flex col-sm-12 col-md-4 align-items-center">
        
                            <a href="javascript:void(0)" (click)="choosePayment('mtn')" class="card-body media align-items-center text-body">
                                <!-- <i class="lnr lnr-chart-bars display-4 d-block text-primary"></i> -->
                                <span class="">
                                    <img src="./assets/img/payment/momo.jpg" class="img-fluid rounded" style="max-width: 90px;" alt="">
                                </span>
                                <span class="media-body d-block ml-3">
                                    <span class="text-big font-weight-bolder">MTN Mobile Money</span><br>
                                    <small class="text-muted">Payer avec MTN Mobile Money</small>
                                </span>
                            </a>
        
                        </div>
                        <div class="d-flex col-sm-12 col-md-4 align-items-center">
        
                            <a href="javascript:void(0)" (click)="choosePayment('orange')" class="card-body media align-items-center text-body">
                                <!-- <i class="lnr lnr-chart-bars display-4 d-block text-primary"></i> -->
                                <span class="">
                                    <img src="./assets/img/payment/om.jpg" class="img-fluid rounded" style="max-width: 90px;" alt="">
                                </span>
                                <span class="media-body d-block ml-3">
                                    <span class="text-big font-weight-bolder">Orange Money</span><br>
                                    <small class="text-muted">Payer avec Orange Money</small>
                                </span>
                            </a>
        
                        </div>
                        <div class="d-flex col-sm-12 col-md-4 align-items-center">
        
                            <a href="javascript:void(0)" (click)="choosePayment('paypal')" class="card-body media align-items-center text-body">
                                <!-- <i class="lnr lnr-chart-bars display-4 d-block text-primary"></i> -->
                                <span class="">
                                    <img src="./assets/img/payment/paypal.png" class="img-fluid rounded" style="max-width: 90px;" alt="">
                                </span>
                                <span class="media-body d-block ml-3">
                                    <span class="text-big font-weight-bolder">Paypal</span><br>
                                    <small class="text-muted">Payer avec paypal </small>
                                </span>
                            </a>
        
                        </div>
                        <div class="d-flex col-sm-12 col-md-4 align-items-center">
        
                            <a href="javascript:void(0)" (click)="choosePayment('uba')" class="card-body media align-items-center text-body">
                                <!-- <i class="lnr lnr-chart-bars display-4 d-block text-primary"></i> -->
                                <span class="">
                                    <img src="./assets/img/payment/uba.png" class="img-fluid rounded" style="max-width: 90px;" alt="">
                                </span>
                                <span class="media-body d-block ml-3">
                                    <span class="text-big font-weight-bolder">UBA</span><br>
                                    <small class="text-muted">Payer avec UBA </small>
                                </span>
                            </a>
        
                        </div>

                    </div>
                </div>
                <!-- / Stats + Links -->
            </div>   
        </div>
        <div class="row justify-content-center align-items-center">
            <div class="col-12 col-md-7 col-lg-6">
                <!-- Now insert cart when user select or not a payment method -->
                <div *ngIf="mode=='nothing'">
                    <div class="card w-100 mb-4">
                        <div class="card-body">
                            <div class="text-muted small">Payment de facture</div>
                            <div class="text-big">Sélectionner un mode de paiement</div>
                        </div>
                        <div class="px-2 mt-4">
                            <div class="w-100" style="min-height: 120px">
                                <p class="text-center"><i class="fa fa-info text-primary fa-4x"></i></p>
                                <p class="text-center text-primary">Choisir un mode...</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="mode=='mtn'">
                    <app-mtn-payment [command]="command" [mode]="choosingMode" (closeMode)="closePayment($event)"></app-mtn-payment>
                </div>
                <div *ngIf="mode=='orange'">
                    <app-orange-payment [command]="command" [mode]="choosingMode" (closeMode)="closePayment($event)"></app-orange-payment>
                </div>
                <div *ngIf="mode=='paypal'">
                    <app-paypal-payment [command]="command" [mode]="choosingMode" (closeMode)="closePayment($event)"></app-paypal-payment>
                </div>
                <div *ngIf="mode=='uba'">
                    <app-uba-payment [command]="command" (closeMode)="closePayment($event)"></app-uba-payment>
                </div>
            </div>
        </div>
    
        <div class="row justify-content-center align-items-center">
            <div class="col-xl-5">
                <!-- Tasks -->
                <div class="card mb-4">
                    <h6 class="card-header with-elements">
                        <div class="card-header-title">Commande</div>
                        <div class="card-header-elements ml-auto">
                            <button type="button" class="btn btn-default btn-xs md-btn-flat">Informations <i class="fa fa-arrow"></i></button>
                        </div>
                    </h6>
                    <div class="card-body">
                        <div class="d-flex justify-content-between mb-2">
                            <p class="text-muted small col">Numéro</p>
                            <p class="small text-primary">
                                {{ command?.idcommande }}
                            </p>
                        </div>
                        <div class="d-flex justify-content-between mb-2">
                            <p class="text-muted small col">Prix total</p>
                            <p class="small text-primary">
                                {{ command?.prixtotal|number }} XAF
                            </p>
                        </div>
                    </div>
                    <div class="card-footer">
                        <p class="text-muted text-center">
                            Informations concernant la commande
                        </p>
                    </div>
                </div>
                <!-- / Tasks -->
            </div>
        </div>
    
</section>

<ng-template #payBlockTemplate>
    <div class="block-ui-template">
        <div class="sk-wave sk-primary mx-auto">
            <div class="sk-wave-rect"></div>
        </div>
    </div>
</ng-template>
