import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Service } from '../../administration/service/model/service';
import { MeetingImmo } from '../../dashboard/meeting/model/meeting';
import { Order } from '../../dashboard/order/model/order';
import { House } from '../house/model/house';
import { OrderImmo } from '../model/order-immo';
import { TarifImmo } from '../model/tarif-immo';

@Injectable({
  providedIn: 'root'
})
export class ImmoService {
  private baseUrl = environment.BASE_URL + 'admin/';

  constructor(private http: HttpClient) { }

  getList(service: Service): Observable<Array<OrderImmo>> {
    return this.http.post<Array<OrderImmo>>(this.baseUrl + 'listcmdimmobilier', service);
  }

  getById(id: number): Observable<OrderImmo> {
    return this.http.get<OrderImmo>(this.baseUrl + 'getcmdimmo/' + id);
  }

  create(order: OrderImmo): Observable<OrderImmo> {
    return this.http.post<OrderImmo>(this.baseUrl + 'addcmdimo', order);
  }

  changeOrderStatus(id: number, status: string): Observable<OrderImmo> {
    return this.http.post<OrderImmo>(this.baseUrl + 'updcmdimmo', {
      idcommmande: id,
      status: status
    });
  }

  makeRDVImmo(meeting: MeetingImmo){
    
  }
  getListTarif(house: House): Observable<Array<TarifImmo>> {
    return this.http.post<Array<TarifImmo>>(this.baseUrl + 'listetarifimmo', house);
  }

  delete(order: OrderImmo): Observable<boolean> {
    return this.http.post<boolean>(this.baseUrl + 'deletecmdimo', order);
  }
}
