<nav class="footer" [ngClass]="currentBg()">
  <div class="container-fluid d-flex flex-wrap justify-content-between text-center container-p-x pb-3">
    <div class="pt-3">
      <span class="footer-text font-weight-bolder">AGZ GROUP</span> ©
    </div>
    <div>
      <!--<a href="javascript:void(0)" class="footer-link pt-3">About Us</a>
      <a href="javascript:void(0)" class="footer-link pt-3 ml-4">Help</a>
      <a href="javascript:void(0)" class="footer-link pt-3 ml-4">Contact</a>
      <a href="javascript:void(0)" class="footer-link pt-3 ml-4">Terms &amp; Conditions</a>-->
    </div>
  </div>
</nav>
