import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { House } from '../model/house';
import {Car} from "../../../auto/car/model/car";

@Injectable({
  providedIn: 'root'
})
export class HouseService {
  private baseUrl = environment.BASE_URL + 'admin/';

  constructor(private http: HttpClient) { }

  getList(): Observable<Array<House>> {
    return this.http.get<Array<House>>(this.baseUrl + 'listimmobiliers');
  }

  saveHouse(house: House): Observable<House> {
    return this.http.post<House>(this.baseUrl + 'addimmobilier', house);
  }

  getById(id: number): Observable<House> {
    return this.http.get<House>(this.baseUrl + 'getimmobilier/' + id);
  }
  uploadImage(image): Observable<any> {
    return this.http.post(this.baseUrl + 'uploadimage', image);
  }

  delete(house: House): Observable<boolean> {
    return this.http.post<boolean>(this.baseUrl + 'delimmobilier', house);
  }
}
