import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, Input } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { LayoutService } from 'src/app/layout/layout.service';
import { ThemeSettingsService } from 'src/vendor/libs/theme-settings/theme-settings.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Order } from '../../../feature/dashboard/order/model/order';
import { Payment } from '../../../feature/dashboard/payment/model/payment';
import { PaymentService } from 'src/app/feature/dashboard/payment/service/payment.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styles: [
  ],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaymentComponent implements OnInit {
  @Input() command: Order;
  mode = 'nothing';
  payments: Payment[] = [];
  choosingMode?: Payment;

  @BlockUI('serviceCreateUIBlock') serviceBlockUIElement: NgBlockUI;
  @BlockUI('payCreateUIBlock') payBlockUIElement: NgBlockUI;
  momoForm: FormGroup = new FormGroup({
    phone: new FormControl('', Validators.required)
  })
  constructor(private appService: AppService, public modal: NgbModal, private toast: ToastrService, private paymentService: PaymentService) {
    this.appService.pageTitle = 'Paiement de Facture';
    this.serviceBlockUIElement.start();
    this.payBlockUIElement.stop();
  }

  ngOnInit(): void {
    console.log('Here the on init');
    const commandPayMode = this.command.modepaiementid?.nommode.toLowerCase();
    if (commandPayMode?.startsWith('ren') || commandPayMode?.startsWith('pai')) {
      this.toast.info(`Vous aviez choisi le mode ${commandPayMode} lors de la création de votre commande`, "Information");
      this.toast.warning('Effectuer un paiement avec l\'un des moyens suivant modifiera le mode de paiement choisi', "Attention");
    }

    this.initData(this.command);
  }

  initData(commande: Order) {
    this.paymentService.getList().subscribe(
      data => {
        this.payments = data;
        this.serviceBlockUIElement.stop();
      },
      (error: HttpErrorResponse) => {
        if (error.status === 0) {
          this.toast.warning('Vérifier votre connexion internet !', 'Attention');
        }
        this.toast.error('Erreur inconnue ! Veuillez réouvrir cette page');
        this.serviceBlockUIElement.stop();
        this.modal.dismissAll();
      }
    );
  }

  choosePayment(selectMode: string) {
    this.choosingMode = this.fecthAndGetMode(selectMode);
    this.mode = selectMode;
    this.payBlockUIElement.start();
  }

  fecthAndGetMode(mode) {
    for (const payMode of this.payments) {
      if (payMode.nommode.toLowerCase().startsWith(mode)) {
        return payMode;
      }
    }
    return null;
  }

  closePayment(event) {
    this.mode = 'nothing';
    this.payBlockUIElement.stop();
  }
}

