<nav class="navbar navbar-expand-lg align-items-lg-center container-p-x" [ngClass]="currentBg()">

  <!-- Brand demo (see demo.css) -->
  <a routerLink="/" class="navbar-brand app-brand demo d-lg-none py-0 mr-4">
    <span class="app-brand-text demo font-weight-normal ml-2">AGZ GROUP</span>
  </a>

  <!-- Sidenav toggle (see demo.css) -->
  <div class="layout-sidenav-toggle navbar-nav d-lg-none align-items-lg-center mr-auto" *ngIf="sidenavToggle">
    <a class="nav-item nav-link px-0 mr-lg-4" href="javascript:void(0)" (click)="toggleSidenav()">
      <i class="ion ion-md-menu text-large align-middle"></i>
    </a>
  </div>

  <button class="navbar-toggler" type="button" (click)="isExpanded = !isExpanded">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="navbar-collapse collapse" [ngbCollapse]="!isExpanded">
    <!-- Divider -->
    <hr class="d-lg-none w-100 my-2">

    <div class="navbar-nav align-items-lg-center">
      <!-- Refresh button -->
      <label class="nav-item navbar-text navbar-search-box p-0 active border-0">
        <button class="btn icon-btn btn-md btn-outline-secondary pt-1 border-0" routerLink="/dashboard">
          <span class="ion ion-md-home font-weight-semibold text-large"></span>
        </button>
      </label>

      <!-- &nbsp;&nbsp;&nbsp; -->

      <div ngbDropdown placement="bottom-left" class="demo-navbar-user nav-item">
        <a ngbDropdownToggle class="nav-link" href="javascript:void(0)">
          <span class="d-inline-flex flex-lg-row-reverse align-items-center align-middle">
            <!--<img src="assets/img/avatars/1.png" alt class="d-block ui-w-30 rounded-circle">-->
            <span class="px-1 mr-lg-2 ml-2 ml-lg-0"><b>PRODUITS</b></span>
          </span>
        </a>
        <div ngbDropdownMenu class="dropdown-menu-right">
          <a href="javascript:void(0)" class="dropdown-item" routerLink="/auto/cars" *ngIf="auto"><i
              class="ion ion-md-car"></i> &nbsp; Véhicules</a>
          <div class="dropdown-divider" *ngIf="immo"></div>
          <a href="javascript:void(0)" class="dropdown-item" routerLink="/immo/housings" *ngIf="immo"><i
              class="ion ion-md-home"></i> &nbsp; Immobilisations</a>
          <div class="dropdown-divider"  *ngIf="agence"></div>
          <a href="javascript:void(0)" class="dropdown-item" routerLink="/agence/hotels" *ngIf="agence"><i
              class="ion ion-md-build"></i> &nbsp; Hotels</a>

          <div class="dropdown-divider"  *ngIf="agence"></div>
          <a href="javascript:void(0)" class="dropdown-item" routerLink="/agence/tourisms" *ngIf="agence"><i
              class="ion ion-md-business"></i> &nbsp; Tourismes</a>

          <div class="dropdown-divider"  *ngIf="style"></div>
          <a href="javascript:void(0)" class="dropdown-item" routerLink="/couture/modeles" *ngIf="style"><i
              class="ion ion-md-business"></i> &nbsp; Models</a>

          <div class="dropdown-divider" *ngIf="vente"></div>
          <a href="javascript:void(0)" class="dropdown-item" routerLink="/ecommerce/produits" *ngIf="vente"><i
              class="ion ion-md-business"></i> &nbsp; Ecommerce(Produits)</a>

          <div class="dropdown-divider" *ngIf="formation"></div>
          <a href="javascript:void(0)" class="dropdown-item" routerLink="/formation/formations" *ngIf="formation"><i
              class="ion ion-md-business"></i> &nbsp; Formations</a>

          <div class="dropdown-divider" *ngIf="resto"></div>
          <a href="javascript:void(0)" class="dropdown-item" routerLink="/restaurant/table-restaurants" *ngIf="resto"><i
              class="ion ion-md-business"></i> &nbsp; Restaurants(Tables)</a>

          <div class="dropdown-divider" *ngIf="resto"></div>
          <a href="javascript:void(0)" class="dropdown-item" routerLink="/restaurant/menu-restaurants" *ngIf="resto"><i
              class="ion ion-md-business"></i> &nbsp; Restaurants(Menus)</a>

          <div class="dropdown-divider" *ngIf="bien"></div>
            <a href="javascript:void(0)" class="dropdown-item" routerLink="/bien-etre/bienetres" *ngIf="bien"><i
                class="ion ion-md-business"></i> &nbsp; Bien Etre</a>

          <div class="dropdown-divider"  *ngIf="evt"></div>
            <a href="javascript:void(0)" class="dropdown-item" routerLink="/evenement/evenements" *ngIf="evt"><i
              class="ion ion-md-business"></i> &nbsp; Evenements</a>
        </div>
      </div>
    </div>

    <div class="navbar-nav align-items-lg-center ml-auto">
      <!-- <div ngbDropdown [placement]="isRTL ? 'bottom-left' : 'bottom-right'"
        class="demo-navbar-notifications nav-item mr-lg-3">
        <a ngbDropdownToggle class="nav-link hide-arrow" href="javascript:void(0)">
          <i class="ion ion-md-notifications-outline navbar-icon align-middle"></i>
          <span class="badge badge-primary badge-dot indicator"></span>
          <span class="d-lg-none align-middle">&nbsp; Notifications</span>
        </a> -->
        <!--<div ngbDropdownMenu [class.dropdown-menu-right]="!isRTL">
          <div class="bg-primary text-center text-white font-weight-bold p-3">
            1 New Notification
          </div>
          <div class="list-group list-group-flush">

            <a href="javascript:void(0)" class="list-group-item list-group-item-action media d-flex align-items-center">
              <div class="ui-icon ui-icon-sm ion ion-md-warning bg-warning border-0 text-body"></div>
              <div class="media-body line-height-condenced ml-3">
                <div class="text-body">99% server load</div>
                <div class="text-light small mt-1">
                  Etiam nec fringilla magna. Donec mi metus.
                </div>
                <div class="text-light small mt-1">
                  20h ago
                </div>
              </div>
            </a>
          </div>
        </div>-->
      <!-- </div> -->
      <!-- Divider -->
      <div class="nav-item d-none d-lg-block text-big font-weight-light line-height-1 opacity-25 mr-3 ml-1">|</div>

      <div ngbDropdown [placement]="isRTL ? 'bottom-left' : 'bottom-right'" class="demo-navbar-user nav-item">
        <a ngbDropdownToggle class="nav-link" href="javascript:void(0)">
          <span class="d-inline-flex flex-lg-row-reverse align-items-center align-middle">
            <!--<img src="assets/img/avatars/1.png" alt class="d-block ui-w-30 rounded-circle">-->
            <span class="px-1 mr-lg-2 ml-2 ml-lg-0"><b>{{ currentUser?.username }}</b></span>
          </span>
        </a>
        <div ngbDropdownMenu [class.dropdown-menu-right]="!isRTL">
          <a href="javascript:void(0)" class="dropdown-item"><i class="ion ion-ios-person text-lightest"></i> &nbsp; Mon
            Profil</a>
          <div class="dropdown-divider"></div>
          <a href="javascript:void(0)" class="dropdown-item" (click)="logout()"><i
              class="ion ion-ios-log-out text-danger"></i> &nbsp; Se déconnecter</a>
        </div>
      </div>
    </div>
  </div>
</nav>
