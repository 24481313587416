<div class="card w-100 mb-4 block-ui-white">
    <div class="card-body">
        <button type="button" class="btn btn-sm btn-outline-primary icon-btn float-right" (click)="closeAndCancel()">
            <i class="fa fa-times"></i>
        </button>
        <div class="text-muted small">Paypal</div>
        <div class="text-big"><small class="text-primary">*</small>Payer avec Paypal</div>
    </div>
    <div class="card-body px-4 mt-2">
        <div class="w-100" style="min-height: 120px">
            <!-- <p class="text-center">Some description</p> -->
            <div class="row justify-content-center align-items-center mb-2">
                <img src="./assets/img/payment/paypal.png" style="max-width: 120px;" alt="">
            </div>
            <div class="row justify-content-center mt-2">
                <ngx-paypal [config]="payPalConfig"></ngx-paypal>
            </div>
        </div>
    </div>
    <div class="card-footer">
        <p class="text-muted" *ngIf="status; else endProcess">Veuillez patienter que le bouton de paiement s'affiche </p>
        <ng-template #endProcess><p class="text-muted" *ngIf="status">Veuillez terminer le processus de paiement </p></ng-template>
    </div>
</div>