import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { User } from 'src/app/auth/model/user';
import { Service } from 'src/app/feature/administration/service/model/service';
import { ServiceEnum } from 'src/app/shared/enums/service-enum';
import { environment } from 'src/environments/environment';
import { MenuRestaurant, TarifMenuRestaurant } from '../model/menu-restaurant';

@Injectable({
  providedIn: 'root'
})
export class MenuRestoService {
  private baseUrl = environment.BASE_URL + 'admin/';

  constructor(private http: HttpClient) { }

  getList(): Observable<MenuRestaurant[]> {
    return this.http.get<MenuRestaurant[]>(this.baseUrl + 'listmenurestaurant');
  }

  getMenuRestaurantById(idmenurestaurant: number): Observable<MenuRestaurant> {
    return this.http.get<MenuRestaurant>(this.baseUrl + 'getmenurestaurant/' + idmenurestaurant);
  }

  CreateOrUpdateMenuRestaurant(menurestaurant: MenuRestaurant): Observable<MenuRestaurant> {
    return this.http.post<MenuRestaurant>(this.baseUrl + 'addmenurestaurant', menurestaurant);
  }

  getUsersList(): Observable<User[]> {
    return this.http.get<User[]>(this.baseUrl + 'listusers/CUSTOMER');
  }

  uploadImage(image): Observable<any> {
    return this.http.post(this.baseUrl + 'uploadimage', image);
  }

  delete(menurestaurant: MenuRestaurant): Observable<boolean> {
    return this.http.post<boolean>(this.baseUrl + 'delmenurestaurant', menurestaurant);
  }
  getServiceInfo(): Observable<Service> {
    return this.http.get<Service>(this.baseUrl + 'getservices/' + ServiceEnum.RESTAURATION);
  }

  getTarifInfo(id): Observable<TarifMenuRestaurant> {
    return this.http.get<TarifMenuRestaurant>(this.baseUrl + 'gettarifmenurestaurant/' + id);
  }

  getAllMenuRestaurantTarif(menurestaurant: MenuRestaurant): Observable<TarifMenuRestaurant[]> {
    return this.http.post<TarifMenuRestaurant[]>(this.baseUrl + 'listetarifmenurestaurant', menurestaurant);
  }
}
