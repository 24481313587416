
export class User {
    iduser: number;
    username: string;
    password: string;
    nom: string;
    tel: string;
    email: string;
    role: string;
    datnais: string;
    lieu: string
    isnotify: boolean;
    requestMessage: string;

    token: string;

}
