import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ToastrService } from 'ngx-toastr';
import { AppService } from 'src/app/app.service';
import { HttpErrorResponse } from '@angular/common/http';
import { PaymentService } from 'src/app/feature/dashboard/payment/service/payment.service';
import { Payment } from '../../../../feature/dashboard/payment/model/payment';

@Component({
  selector: 'app-mtn-payment',
  templateUrl: './mtn-payment.component.html',
  styles: [
  ]
})
export class MtnPaymentComponent implements OnInit {
  @Input() command: any;
  @Output() closeMode = new EventEmitter<boolean>();
  @Input() mode: Payment;
  status = 'en attente';
  @BlockUI('paymentCreateUIBlock') blockUIElement: NgBlockUI;
  momoForm: FormGroup = new FormGroup({
    phone: new FormControl('', Validators.required)
  })
  constructor(private appService: AppService, public modal: NgbModal, private toast: ToastrService, private payService: PaymentService) {
    this.appService.pageTitle = 'Paiement de Facture';
  }

  ngOnInit(): void {
    console.log("Here the on init");
  }

  closeAndCancel() {
    this.closeMode.emit(true);
  }

  startPay() {
    if (this.momoForm.invalid) {
      this.toast.warning("Veuillez remplir tous les champs", "Attention");
    } else {
      const phone = this.momoForm.value.phone.toString();
      // const phone = 'sas';
      const second = phone.substring(0, 2);
      if (isNaN(Number(phone))) {
        this.toast.warning("Veuillez saisir un numéro de téléphone correct !", "Attention");
      } else {
        if (phone.length != 9) {
          this.toast.warning("Le numéro de téléphone doit contenir au plus 9 chiffres !", "Attention");
        } else if (second == '67' || second == '68') {
          this.submit(phone);
        } else if (second == '65') {
          if (parseInt(phone.charAt(2)) >= 5) {
            this.toast.warning("Saisir un numéro MTN Cameroun correct !", "Attention");
          } else {
            this.submit(phone);
          }
        } else {
          this.toast.warning("Saisir un numéro MTN Cameroun correct !", "Attention");
        }
      }
    }
  }

  submit(phone) {
    const tel = parseInt(phone);
    const idCmd = this.command.idcommande;

    // Call the payment method
    this.toast.info('Début des opérations !', 'Information');
    this.blockUIElement.start();
    this.payService.assignPaymentMode(idCmd, this.mode.idmodepaiement).subscribe(
      res => {
        this.payService.payWithMomoOrOM(idCmd, tel).subscribe(
          data => {
            this.toast.success("Processus de paiement initié avec succès !");
            this.toast.info("Début des opérations", "Information");
            // this.modal.dismissAll();
            setInterval(() => {
              this.getTheStatus(idCmd);
            }, 10000);
            // this.getTheStatus();
          }, (error: HttpErrorResponse) => {
            if (error.status === 200) {
              this.toast.success("Processus de paiement initié avec succès !");
              this.toast.info("Début des opérations", "Information");
              // this.modal.dismissAll();
              setInterval(() => {
                this.getTheStatus(idCmd);
              }, 10000);
            } else {
              this.toast.error("paiement impossible", "Erreur");
              this.toast.error(`Message: ${error.message}`, 'Erreur');
              this.blockUIElement.stop();
            }
          }
        )
      }, (error: HttpErrorResponse) => {
        this.toast.error("Echec d'initiation de paiement", "Erreur");
        this.toast.error(`Message: ${error.message}`, 'Erreur');
        this.blockUIElement.stop();
      }
    )
  }

  getTheStatus(idCmd: number) {
    this.payService.getMomoStatus(idCmd).subscribe(
      res => {
        if (res?.toLowerCase().startsWith('en')) {
          this.toast.info(`Status: ${res}`, "Status de la commande");
        } else if (res?.toLowerCase().startsWith('pa')) {
          this.toast.success(`Commande réglée avec succès`, "Status de la commande");
          // this.modal.dismissAll();
          location.reload();
        } else {
          this.toast.info("Status: En attente", "Status de la commande");
        }
      }, (error: HttpErrorResponse) => {
        console.log("Error", error);
      }
    )
  }
}

