import { Injectable } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Payment } from '../model/payment';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  private baseUrl = environment.BASE_URL + 'admin/';
  private base2Url = environment.BASE_URL + 'client/';

  constructor(private http: HttpClient) { }

  getList(): Observable<Array<Payment>> {
    return this.http.get<Array<Payment>>(this.baseUrl + 'listemodepaiement');
  }

  getById(id: number): Observable<Payment> {
    return this.http.get<Payment>(this.baseUrl + 'getmodepaiement/' + id);
  }

  // getRDVPayment(): Observable<Payment> {
  //   return this.http.get<Payment>(this.baseUrl + 'getmodepaiement/' + id);
  // }

  create(payment: Payment): Observable<Payment> {
    return this.http.post<Payment>(this.baseUrl + 'addmodepaiement', payment);
  }

  update(id: number, payment: Payment): Observable<Payment> {
    return this.http.post<Payment>(this.baseUrl + 'addmodepaiement', payment);
  }

  payWithMomoOrOM(idcmd, tel, url?: string): Observable<string> {
    return this.http.post<string>(this.base2Url + 'addpay', {
      commandeid: {
        idcommande: idcmd
      },
      telclient: tel,
      url: url ? url : '',
    });
  }

  assignPaymentMode(cmdId: number, paymentId: number): Observable<any> {
    return this.http.post<any>(`${this.base2Url}updstcmdpaymod`, {
      idcommmande: cmdId,
      modepaiement: paymentId,
    });
  }

  savePaypalOrderId(cmdId: number, orderId: string): Observable<string> {
    return this.http.get<string>(this.base2Url + `updatcmdpaybal/${cmdId}/${orderId}`);
  }

  changePaymentStatus(orderId: string): Observable<string> {
    return this.http.get<string>(this.base2Url + `changestatuscmd/${orderId}`);
  }

  getMomoStatus(command: number): Observable<string> {
    return this.http.post<string>(`${this.base2Url}addpaystatusmomo`, {
      idpaiement: 0,
      commandeid: {
        idcommande: command
      }
    });
  }

  getOMStatus(command: number): Observable<string> {
    return this.http.post<string>(`${this.base2Url}listomstatus`, {
      idpaiement: 0,
      commandeid: {
        idcommande: command
      }
    });
  }
}
