<div
  class="card w-100 mb-4 block-ui-white" *blockUI="'paymentCreateUIBlock'; template: paymentBlockTemplate"
>
  <div class="card-body">
    <button
      type="button"
      class="btn btn-sm btn-outline-primary icon-btn float-right"
      (click)="closeAndCancel()"
    >
      <i class="fa fa-times"></i>
    </button>
    <section >
      <div class="card p-0 border-0 m-0">
        <div class="card-body p-0 mb-2">
          <p class="text-center">
            <img
              src="./assets/img/stripe.png"
              alt=""
              style="max-height: 100px"
              class="img-fluid rounded"
            />
          </p>
          <h5 class="text-center text-muted">Payer avec UBA</h5>
        </div>
      </div>

      <form [formGroup]="ubaform" (ngSubmit)="submit()">
        <div class="col-lg-12">
          <div class="form-group">
            <label>
              <h5 class="text-muted">Nom <span class="required">*</span></h5>
            </label>
            <input
              class="form-control"
              placeholder="Nom"
              formControlName="name"
            />
          </div>
        </div>
        <div class="col-lg-12">
          <div class="form-group">
            <label>
              <h5 class="text-muted">Prénom <span class="required">*</span></h5>
            </label>
            <input
              class="form-control"
              placeholder="Prénom"
              formControlName="firstname"
            />
          </div>
        </div>

        <div class="col-lg-12">
          <div class="form-group">
            <label>
              <h5 class="text-muted">email <span class="required">*</span></h5>
            </label>
            <input
              type="email"
              class="form-control"
              placeholder="Email"
              formControlName="email"
            />
          </div>
        </div>
        <div class="col-lg-12">
          <div class="form-group">
            <label>
              <h5 class="text-muted">
                Telephone <span class="required">*</span>
              </h5>
            </label>
            <input
              type="tel"
              class="form-control"
              placeholder="Votre numéro de téléphone"
              formControlName="phone"
            />
          </div>
        </div>
         <div class="row justify-content-center align-items-center">
              <button type="submit" class="btn btn-primary">Payer Maintenant</button>
          </div>
      </form>
    </section>
  </div>
</div>

<ng-template #paymentBlockTemplate>
    <div class="block-ui-template">
        <div class="sk-wave sk-primary mx-auto">
            <div class="sk-wave-rect"></div>
            <div class="sk-wave-rect"></div>
            <div class="sk-wave-rect"></div>
            <div class="sk-wave-rect"></div>
            <div class="sk-wave-rect"></div>
        </div>
    </div>
</ng-template>

