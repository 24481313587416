import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  loading = new BehaviorSubject(false);

  constructor() {
  }

  show() {
    this.loading.next(true);
  }

  hide() {
    setTimeout(() => this.loading.next(false), 1000);
  }

}
