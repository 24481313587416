import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ChartsModule} from 'ng2-charts';
import {PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {FormsModule} from '@angular/forms';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { LoaderComponent } from './components/loader/loader/loader.component';



@NgModule({
  declarations: [LoaderComponent],
  imports: [
    FormsModule,
    NgbModule,
    CommonModule,

    ChartsModule,
    PerfectScrollbarModule,
  ],
  exports: [
    LoaderComponent
  ]

})
export class CoreModule { }
