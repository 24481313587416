import {Component, Input, HostBinding, OnInit} from '@angular/core';
import { AppService } from '../../app.service';
import { LayoutService } from '../../layout/layout.service';
import {User} from '../../auth/model/user';
import {AuthService} from '../../auth/service/auth.service';
import {Router} from "@angular/router";
import { ToastrService } from 'ngx-toastr';
import { PaymentService } from 'src/app/feature/dashboard/payment/service/payment.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-layout-navbar',
  templateUrl: './layout-navbar.component.html',
  styles: [':host { display: block; }']
})
export class LayoutNavbarComponent implements OnInit{
  isExpanded = false;
  isRTL: boolean;
  currentUser: User;

  immo = false;
  auto = false;
  super = false;
  agence = false;
  style = false;
  formation = false;
  resto = false;
  bien = false;
  evt = false;
  vente = false;

  @Input() sidenavToggle = true;

  @HostBinding('class.layout-navbar') hostClassMain = true;

  constructor(private appService: AppService, private layoutService: LayoutService,
              private authService: AuthService,
              private payService: PaymentService,
              private toast: ToastrService,
              private router: Router) {
    this.isRTL = appService.isRTL;
  }

  ngOnInit() {
    this.currentUser = this.authService.currentUserValue();

    const privs = this.authService.getPriviledges().split(',');
    for (const pr of privs) {
      if(pr === 'ROLE_SUPER'){
        this.immo = true;
        this.auto = true;
        this.super = true;
        this.agence = true;
        this.style = true;
        this.formation = true;
        this.resto = true;
        this.bien = true;
        this.evt = true;
        this.vente = true;
        break;
      }
      if(pr === 'ROLE_VEHI') {
        this.auto = true;
        continue;
      }
      if(pr === 'ROLE_IMMO') {
        this.immo = true;
        continue;
      }
      if(pr === 'ROLE_FORMA') {
        this.formation = true;
        continue;
      }
      if(pr === 'ROLE_VOYAGE') {
        this.agence = true;
        continue;
      }
      if(pr === 'ROLE_STYHAUT') {
        this.style = true;
        continue;
      }
      if(pr === 'ROLE_TRAITE') {
        this.resto = true;
        continue;
      }
      if(pr === 'ROLE_BIENETR') {
        this.bien = true;
        continue;
      }
      if(pr === 'ROLE_EVNT') {
        this.evt = true;
        continue;
      }
      if(pr === 'ROLE_VENTE') {
        this.vente = true;
        continue;
      }
    }
  }

  currentBg() {
    return `bg-${this.appService.layoutNavbarBg}`;
  }

  logout() {
    this.authService.logout();
    this.router.navigate(['/login']);
  }

  toggleSidenav() {
    this.layoutService.toggleCollapsed();
  }

  checkStatusOM() {
    if(localStorage.getItem('cmdOM')) {
      const commandId = Number(localStorage.getItem('cmdOM'));
      this.toast.info(`Vérification du status de la commande ${commandId}`, "Veuillez patienter");
      this.payService.getOMStatus(commandId).subscribe(
        data => {
          console.log("The data", data);
          localStorage.removeItem('cmdOM');
        }, (error: HttpErrorResponse) => {
          console.error("An error has occured !", error.error.text);
        }
      )
    }
  }
}
