import {Category} from '../../category/model/category';
import {Table} from '../../table/model/table';

export class Service {
    idservice: number;
    nomservice: string;

    categorieid: Category;
    adtableid: Table;
    icone: string;
    image: string
}
