export class House {
    idimmobilier: number;
    disponible: boolean;
    typeimmo: string;
    ville: string;
    quartier: string;
    description: string;
    tel: string;
    email: string;
    dateexp: string;
    status: string;
    datpub?: string;
    prixvente: number;
    prixlocation: number;
    nbpiece: string;
    surface: string;
    image1: string;
    image2: string;
    image3: string;
    image4: string;
    image5: string;
    position: number
}
