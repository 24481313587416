import { Component, OnInit } from '@angular/core';
import { PaymentService } from '../../../../feature/dashboard/payment/service/payment.service';
import { ActivatedRoute } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-check-status',
  templateUrl: './check-status.component.html',
  styles: [
  ]
})
export class CheckStatusComponent implements OnInit {
  status = 'Veuillez patienter';
  imageSource = './assets/img/payment/pending.gif';
  isError = false;
  constructor(
    private route: ActivatedRoute,
    private payService: PaymentService,
    private toast: ToastrService) { }

  ngOnInit(): void {
    console.log("Url href",location.href)
    console.log("Url hostname",location.host)
    const param = this.route.snapshot.params
    if (param.id) {
      try {
        const paramValue = Buffer.from(param.id, 'base64');
        this.initData(paramValue.toString('utf-8'));

      } catch (error) {
        setTimeout(() => {
          this.toast.warning("Attention a vos saisies", "Attention");
        }, 3000);
        location.href = '/';
      }
    }
  }

  initData(id) {
    this.payService.getOMStatus(Number(id)).subscribe(
      data => {
        if (data.toLowerCase().startsWith('su') || data.toLowerCase().startsWith('pay')) {
          this.status = "Votre paiement a été approuvé avec succès!";
          this.imageSource = './assets/img/payment/success.png';
        } else if (data.toLowerCase().startsWith('pe') || data.toLowerCase().startsWith('en')) {
          this.status = "Votre paiement est toujours en attente";
          this.imageSource = './assets/img/payment/pending.gif';
        } else {
          this.status = "Le paiement de votre commande a échoué";
          this.imageSource = './assets/img/payment/error.png';
        }
        this.isError = false;
      }, (error: HttpErrorResponse) => {
        console.log("Error", error);
        this.toast.error("Une erreur est survenue", "Impossible de recuperer les données");
        this.isError = true;
        Swal.fire({
          title: 'Une erreur est survenue',
          text: 'Impossible de recupérer vos informations',
          icon: 'error',
          confirmButtonText: 'Raffraîchir',
          cancelButtonText: 'Fermer',
          showCancelButton: true
        }).then(
          result => {
            if (result.isConfirmed) {
              this.initData(id);
            } else {
              location.href = '/';
            }
          }
        )
      }
    )
  }

}
