import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AuthService} from '../service/auth.service';

@Injectable()
export class JwtHeaderInterceptor implements HttpInterceptor {

    constructor(private authService: AuthService) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // ajouter Authorization à l'entete de la requete is l'utilisateur est connecté
        const currentUser = this.authService.currentUserValue();
        const isLoggedIn = currentUser && currentUser.token;

        if (isLoggedIn) {
            req = req.clone({
                setHeaders: {
                    Authorization: `Bearer ${currentUser.token}`
                },
                withCredentials: false
            });
        }

        return next.handle(req);
    }

}
