<sidenav [orientation]="orientation" [ngClass]="getClasses()">

  <!-- Brand demo (see src/demo.css) -->
  <div class="app-brand demo" *ngIf="orientation !== 'horizontal'">
    <!--<span class="app-brand-logo demo bg-primary">
      <svg viewBox="0 0 148 80" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><defs><linearGradient id="a" x1="46.49" x2="62.46" y1="53.39" y2="48.2" gradientUnits="userSpaceOnUse"><stop stop-opacity=".25" offset="0"></stop><stop stop-opacity=".1" offset=".3"></stop><stop stop-opacity="0" offset=".9"></stop></linearGradient><linearGradient id="e" x1="76.9" x2="92.64" y1="26.38" y2="31.49" xlink:href="#a"></linearGradient><linearGradient id="d" x1="107.12" x2="122.74" y1="53.41" y2="48.33" xlink:href="#a"></linearGradient></defs><path style="fill: #fff;" transform="translate(-.1)" d="M121.36,0,104.42,45.08,88.71,3.28A5.09,5.09,0,0,0,83.93,0H64.27A5.09,5.09,0,0,0,59.5,3.28L43.79,45.08,26.85,0H.1L29.43,76.74A5.09,5.09,0,0,0,34.19,80H53.39a5.09,5.09,0,0,0,4.77-3.26L74.1,35l16,41.74A5.09,5.09,0,0,0,94.82,80h18.95a5.09,5.09,0,0,0,4.76-3.24L148.1,0Z"></path><path transform="translate(-.1)" d="M52.19,22.73l-8.4,22.35L56.51,78.94a5,5,0,0,0,1.64-2.19l7.34-19.2Z" fill="url(#a)"></path><path transform="translate(-.1)" d="M95.73,22l-7-18.69a5,5,0,0,0-1.64-2.21L74.1,35l8.33,21.79Z" fill="url(#e)"></path><path transform="translate(-.1)" d="M112.73,23l-8.31,22.12,12.66,33.7a5,5,0,0,0,1.45-2l7.3-18.93Z" fill="url(#d)"></path></svg>
    </span>-->
    <a routerLink="/" class="app-brand-text demo sidenav-text font-weight-normal ml-2">AGZ GROUP</a>
    <a href="javascript:void(0)" class="layout-sidenav-toggle sidenav-link text-large ml-auto"
      (click)="toggleSidenav()">
      <i class="ion ion-md-menu align-middle"></i>
    </a>
  </div>
  <div class="sidenav-divider mt-0" *ngIf="orientation !== 'horizontal'"></div>

  <!-- Links -->
  <div class="sidenav-inner" [ngClass]="{ 'py-1': orientation !== 'horizontal' }">

    <!-- &lt;!&ndash; MASTER ADMIN &ndash;&gt; -->
    <sidenav-header class="small font-weight-semibold" *ngIf="super">MASTER ADMIN</sidenav-header>
    <sidenav-router-link icon="ion ion-md-settings" route="/administration/categories"
      [active]="isActive('/administration/categories')" *ngIf="super">Catégories</sidenav-router-link>
    <sidenav-router-link icon="ion ion-ios-list-box" route="/administration/services"
      [active]="isActive('/administration/services')" *ngIf="super">Services</sidenav-router-link>
    <sidenav-router-link icon="ion ion-md-build" route="/administration/tables"
      [active]="isActive('/administration/tables')" *ngIf="super">Tables</sidenav-router-link>
    <sidenav-router-link icon="ion ion-ios-link" route="/administration/columns"
      [active]="isActive('/administration/columns')" *ngIf="super">Colonnes</sidenav-router-link>
    <sidenav-router-link icon="ion ion-md-keypad" route="/administration/super-admins"
      [active]="isActive('/administration/super-admins')" *ngIf="super">Super Admin</sidenav-router-link>

    <sidenav-divider class="mb-1" *ngIf="super"></sidenav-divider>
    <!-- TABLEAU DE BORD -->
    <sidenav-header class="small font-weight-semibold">TABLEAU DE BORD</sidenav-header>
    <!--<sidenav-router-link icon="ion ion-md-eye" route="/typography" [active]="isActive('/typography')">Apercu</sidenav-router-link>
    <sidenav-router-link icon="ion ion-md-stats" route="/typography" [active]="isActive('/dahboard')">Statistiques</sidenav-router-link>-->
    <sidenav-router-link icon="ion ion-md-person" route="/dashboard/admins" [active]="isActive('/dashboard/admins')"
      *ngIf="super">Admins</sidenav-router-link>
    <sidenav-router-link icon="ion ion-ios-person" route="/dashboard/customers"
      [active]="isActive('/dashboard/customers')">Clients</sidenav-router-link>
    <!-- <sidenav-router-link icon="ion ion-md-reorder" route="/dashboard/orders" [active]="isActive('/dashboard/orders')">Commandes</sidenav-router-link> -->
    <sidenav-router-link icon="ion ion-md-card" route="/dashboard/payments" [active]="isActive('/dashboard/payments')">
      Moyens de paiement</sidenav-router-link>
    <sidenav-router-link icon="ion ion-md-speedometer" route="/dashboard/meetings"
      [active]="isActive('/dashboard/meetings')">Rendez-vous</sidenav-router-link>
    <sidenav-router-link icon="ion ion-md-settings" route="/dashboard/settings"
      [active]="isActive('/dashboard/settings')">Réglages</sidenav-router-link>


    <sidenav-divider class="mb-1" *ngIf="auto"></sidenav-divider>
    <!-- AGZ AUTOMOBILE -->
    <sidenav-header class="small font-weight-semibold" *ngIf="auto">AGZ AUTOMOBILE</sidenav-header>
    <!--<sidenav-router-link icon="ion ion-md-car" route="/auto/cars" [active]="isActive('/auto/cars')">Véhicules</sidenav-router-link>-->
    <!--<sidenav-router-link icon="ion ion-md-wallet" route="/auto/tariff-cars" [active]="isActive('/auto/tariff-cars')">Tarifaire Véhicules</sidenav-router-link>-->
    <sidenav-router-link icon="ion ion-md-train" route="/auto/transports" [active]="isActive('/auto/transports')"
      *ngIf="auto">Transport Inter Urbain</sidenav-router-link>
    <!--<sidenav-router-link icon="ion ion-md-contacts" route="/auto/car-pooling" [active]="isActive('/auto/car-pooling')">Covoiturage</sidenav-router-link>-->
    <sidenav-router-link icon="ion ion-md-clipboard" route="/auto/rentals" [active]="isActive('/auto/rentals')"
      *ngIf="auto">Locations Ville</sidenav-router-link>
    <sidenav-router-link icon="ion ion-md-clipboard" route="/auto/hv-rentals" [active]="isActive('/auto/hv-rentals')"
      *ngIf="auto">Locations Hors-Ville</sidenav-router-link>
    <sidenav-router-link icon="ion ion-md-briefcase" route="/auto/sales" [active]="isActive('/auto/sales')"
      *ngIf="auto">Ventes</sidenav-router-link>



    <sidenav-divider class="mb-1" *ngIf="immo"></sidenav-divider>
    <!-- AGZ IMMOBILIER -->
    <sidenav-header class="small font-weight-semibold" *ngIf="immo">AGZ IMMOBILIER</sidenav-header>
    <!--<sidenav-router-link icon="ion ion-md-quote" route="/typography" [active]="isActive('/typograph')" disabled>Locations</sidenav-router-link>
      <sidenav-router-link icon="ion ion-md-quote" route="/typography" [active]="isActive('/typograpy')">Ventes</sidenav-router-link>-->
    <sidenav-router-link icon="ion ion-md-quote" route="/immo/rentals" [active]="isActive('/immo/rentals')"
      *ngIf="immo">Locations</sidenav-router-link>
    <sidenav-router-link icon="ion ion-md-quote" route="/immo/sales" [active]="isActive('/immo/sales')" *ngIf="immo">
      Ventes</sidenav-router-link>


    <sidenav-divider class="mb-1" *ngIf="agence"></sidenav-divider>
    <!-- AGZ Agence de voyage -->
    <sidenav-header class="small font-weight-semibold" *ngIf="agence">AGZ AGENCE</sidenav-header>
    <!-- <sidenav-router-link icon="ion ion-md-quote" route="/agence/flies" [active]="isActive('/agence/flies')"
      *ngIf="agence">BIELLETTERIE</sidenav-router-link> -->
    <sidenav-router-link icon="ion ion-md-quote" route="/agence/reserve-hotels"
      [active]="isActive('/agence/reserve-hotels')" *ngIf="agence">RESERVATION HOTEL</sidenav-router-link>
    <sidenav-router-link icon="ion ion-md-quote" route="/agence/reserve-tourisms"
      [active]="isActive('/agence/reserve-tourisms')" *ngIf="agence">TOURISME</sidenav-router-link>
    <!--<sidenav-menu icon="ion ion-ios-albums" text="Layouts" [active]="isMenuActive('/layouts')" [open]="isMenuOpen('/layouts')">
      <sidenav-router-link route="/layouts/options" [active]="isActive('/layouts/options')">Layout options</sidenav-router-link>
      <sidenav-router-link route="/layouts/blank" [active]="isActive('/layouts/blank')">Blank</sidenav-router-link>

      <-- AGZ Couture -->
    <sidenav-divider class="mb-1" *ngIf="style"></sidenav-divider>
    <sidenav-header class="small font-weight-semibold" *ngIf="style">AGZ STYLE ET COUTURE</sidenav-header>
    <sidenav-router-link icon="ion ion-md-quote" route="/couture/reserve-modeles"
      [active]="isActive('/couture/reserve-modeles')" *ngIf="style">COUTURE</sidenav-router-link>

    <!--    AGZ Ecommerce-->
    <sidenav-divider class="mb-1" *ngIf="vente"></sidenav-divider>
    <sidenav-header class="small font-weight-semibold" *ngIf="vente">AGZ ECOMMERCE</sidenav-header>
    <sidenav-router-link icon="ion ion-md-quote" route="/ecommerce/order-produits"
      [active]="isActive('/ecommerce/order-produits')" *ngIf="vente">Ecommerce</sidenav-router-link>

    <!-- AGZ Formation -->
    <sidenav-divider class="mb-1" *ngIf="resto"></sidenav-divider>
    <sidenav-header class="small font-weight-semibold" *ngIf="resto">AGZ RESTAURANT</sidenav-header>
    <sidenav-router-link icon="ion ion-md-quote" route="/restaurant/order-table"
      [active]="isActive('/restaurant/order-table')" *ngIf="resto">Table-Restaurants</sidenav-router-link>
    <sidenav-router-link icon="ion ion-md-quote" route="/restaurant/order-menu"
      [active]="isActive('/restaurant/order-menu')" *ngIf="resto">Menu-Restaurants</sidenav-router-link>

    <!--    AGZ Restauration-->
    <sidenav-divider class="mb-1" *ngIf="formation"></sidenav-divider>
    <sidenav-header class="small font-weight-semibold" *ngIf="formation">AGZ FORMATION</sidenav-header>
    <sidenav-router-link icon="ion ion-md-quote" route="/formation/order-formations"
      [active]="isActive('/formation/order-formations')" *ngIf="formation">Formation</sidenav-router-link>

    <!--    AGZ Bienetre-->
    <sidenav-divider class="mb-1" *ngIf="bien"></sidenav-divider>
    <sidenav-header class="small font-weight-semibold" *ngIf="bien">AGZ BIEN ETRE</sidenav-header>
    <sidenav-router-link icon="ion ion-md-quote" route="/bien-etre/order-bienetres"
      [active]="isActive('/bien-etre/order-bienetres')" *ngIf="bien">Bien-être</sidenav-router-link>

    <!--    AGZ Evenement-->
    <sidenav-divider class="mb-1" *ngIf="evt"></sidenav-divider>
    <sidenav-header class="small font-weight-semibold" *ngIf="evt">AGZ EVENEMENTIEL</sidenav-header>
    <sidenav-router-link icon="ion ion-md-quote" route="/evenement/order-evenements"
      [active]="isActive('/evenement/order-evenements')" *ngIf="evt">Evènements</sidenav-router-link>

  </div>
</sidenav>