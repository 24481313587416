<div class="card w-100 mb-4 block-ui-white" *blockUI="'paymentCreateUIBlock'; template: paymentBlockTemplate">
    <div class="card-body">
        <button type="button" class="btn btn-sm btn-outline-primary icon-btn float-right" (click)="closeAndCancel()">
            <i class="fa fa-times"></i>
        </button>
        <div class="text-muted small">MTN Mobile Money</div>
        <div class="text-big"><small class="text-danger">*</small>Remplir tous les champs</div>
    </div>
    <div class="card-body px-4 mt-2">
        <div class="w-100" style="min-height: 120px">
            <!-- <p class="text-center">Some description</p> -->
            <div class="row justify-content-center align-items-center mb-2">
                <img src="./assets/img/payment/momo.jpg" style="max-width: 120px;" alt="">
            </div>
            <form (ngSubmit)="startPay()" [formGroup]="momoForm">
                <div class="form-group row mb-4">
                    <label for="phoneNumber">Numéro de téléphone <sup class="text-danger">*</sup></label>
                    <input type="text" id="phoneNumber" formControlName="phone" class="form-control">
                </div>

                <!-- Send the transaction now -->
                <div class="row justify-content-center align-items-center">
                    <button type="submit" class="btn btn-primary">Payer Maintenant</button>
                </div>
            </form>
        </div>
    </div>
</div>

<ng-template #paymentBlockTemplate>
    <div class="block-ui-template">
        <div class="sk-wave sk-primary mx-auto">
            <div class="sk-wave-rect"></div>
            <div class="sk-wave-rect"></div>
            <div class="sk-wave-rect"></div>
            <div class="sk-wave-rect"></div>
            <div class="sk-wave-rect"></div>
        </div>
    </div>
</ng-template>