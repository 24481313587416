import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';


// *******************************************************************************
// NgBootstrap

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';


// *******************************************************************************
// Libs

import Swal from 'sweetalert2';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { ToastrModule } from 'ngx-toastr';
import { ConfirmationPopoverModule } from 'angular-confirmation-popover';
import { ContextMenuModule } from 'ngx-contextmenu';
import { AgmCoreModule } from '@agm/core';
import { BlockUIModule } from 'ng-block-ui';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { DragulaModule } from 'ng2-dragula';
import { NgxPayPalModule } from 'ngx-paypal';

// *******************************************************************************
// App

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { AppService } from './app.service';
import { LayoutModule } from './layout/layout.module';
import { ThemeSettingsModule } from '../vendor/libs/theme-settings/theme-settings.module';
import { LoginComponent } from './auth/login/login.component';
import { CoreModule } from './core/core.module';
import { HTTP_INTERCEPTORS, HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { JwtHeaderInterceptor } from './auth/interceptor/jwt.header.interceptor';
import { AuthService } from './auth/service/auth.service';
import { EditorModule, TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';

// *******************************************************************************
// Ngx-SweetAlert2

export async function provideSwal() {
  return Swal.mixin({
    buttonsStyling: false,
    customClass: {
      confirmButton: 'btn btn-lg btn-primary',
      cancelButton: 'btn btn-lg btn-default'
    }
  });
}

// *******************************************************************************
//

@NgModule({
  declarations: [
    AppComponent,
    NotFoundComponent
  ],

  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    HttpClientModule,
    HttpClientJsonpModule,
    NgbModule,

    NgxPayPalModule,

    // App
    AppRoutingModule,
    LayoutModule,
    CoreModule,
    ThemeSettingsModule,

    // Google Maps
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyAwqrRNKkXrLws-HGS44GLKIChgXNj2NcY',
      libraries: ['places'],
      apiVersion: 'quarterly'
    }),

    // Libs
    SweetAlert2Module.forRoot({ provideSwal }),
    ToastrModule.forRoot({
      toastClass: 'toast'
    }),
    ConfirmationPopoverModule.forRoot({
      cancelButtonType: 'default btn-sm',
      confirmButtonType: 'primary btn-sm'
    }),
    ContextMenuModule.forRoot(),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyAwqrRNKkXrLws-HGS44GLKIChgXNj2NcY',
      libraries: ['places'],
      apiVersion: 'quarterly'
    }),

    /*AgmCoreModule.forRoot({
      /!* NOTE: When using Google Maps on your own site you MUST get your own API key:
               https://developers.google.com/maps/documentation/javascript/get-api-key
               After you got the key paste it in the URL below. *!/
      apiKey: 'AIzaSyCHtdj4L66c05v1UZm-nte1FzUEAN6GKBI'
    }),*/
    BlockUIModule.forRoot(),
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    }),
    DragulaModule.forRoot(),

    EditorModule,

  ],

  providers: [
    Title,
    AppService,
    { provide: HTTP_INTERCEPTORS, useClass: JwtHeaderInterceptor, multi: true },
    { provide: TINYMCE_SCRIPT_SRC, useValue: './assets/tinymce/tinymce.min.js' },

    AuthService,
  ],

  bootstrap: [
    AppComponent
  ]
})
export class AppModule { }
