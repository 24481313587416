import { Injectable } from '@angular/core';
import { Payment } from '../../feature/dashboard/payment/model/payment';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  // constructor() { }

  static slicePayment(payments: Payment[]) {
    let data = [];

    for (const pay of payments) {
      if(pay.nommode.toLowerCase().startsWith('ren') || pay.nommode.toLowerCase().startsWith('pai')) {
        data.push(pay);
      }
    }

    return data;
  }
}
