import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { CheckoutService } from '../checkout.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

@Component({
  selector: 'app-uba-payment',
  templateUrl: './uba-payment.component.html',
  styleUrls: ['./uba-payment.component.scss']
})
export class UbaPaymentComponent implements OnInit {
  @Output() newEvent = new EventEmitter<string>();
  @Input() command: any;
  loading = true;
  msg: any;
  @Output() closeMode = new EventEmitter<boolean>();
  @BlockUI('paymentCreateUIBlock') blockUIElement: NgBlockUI;

  ubaform: FormGroup;

  constructor(private fb: FormBuilder, private checkoutService: CheckoutService, private toast: ToastrService) { }

  ngOnInit(): void {
    setTimeout(() => {
      this.loading = false;
    }, 500);
    this.ubaform = this.fb.group({
      name: new FormControl('', [Validators.required]),
      firstname: new FormControl('', [Validators.required]),
      phone: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.email])
    });
  }

  cancelPayment() {
    this.newEvent.emit('');
  }

  submit() {

      const firstname = this.ubaform.get('firstname').value;
      const lastname = this.ubaform.get('name').value;
      const email = this.ubaform.get('email').value;
      const phone = this.ubaform.get('phone').value;
      this.loading = true;
    this.checkoutService.payWithUba(firstname,lastname,email,phone, this.command.idcommande, this.command.prixtotal)
    .subscribe((result)=>{
        console.log(result);
        //const val = result.replace("</footer>", "</footer> <script src=\"https://cm.instantbillspay.com/assets/commonnewer/js/jquery-3.6.0.min.js\"></script>");
        const pop = open(null, 'popup', 'location=no,height=570,width=520,top=150,left=380,scrollbars=yes,status=yes,dependent=yes');
        pop.focus();
        pop.document.write(result);
        pop.document.close();
        pop.onbeforeunload = function (e) {
            window.location.reload()
        };
        this.loading = false;
    }, (error)=>{
        console.log(error)
    });
  }

  closeAndCancel(){
    this.closeMode.emit(true);
  }


}

