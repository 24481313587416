import { Injectable } from '@angular/core';
import {JwtHelperService} from '@auth0/angular-jwt';
import {HttpClient} from '@angular/common/http';
import {User} from '../model/user';
import {environment} from '../../../environments/environment';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private readonly AGZ_USER = 'AGZ_USER';
  private jwtHelperService: JwtHelperService;

  constructor(private httpClient: HttpClient) {
    this.jwtHelperService = new JwtHelperService();
  }

  currentUserValue(): User {
    return JSON.parse(localStorage.getItem(this.AGZ_USER)) as User;
  }

  login(username: string, password: string) {
    return this.httpClient.post<any>(`${environment.BASE_URL}authenticate`, {username, password}, {observe: 'response'})
        .pipe(map(response => {
            /*const token = response.headers.get('X-Auth-Token');*/
            const token = response.body.token || response.body.jwttoken;

            const decodedToken = this.jwtHelperService.decodeToken(token);
            console.log("Decoded", decodedToken);

            const user = new User();
            user.token = token;
            user.username = decodedToken.sub;
            user.role = decodedToken.role[0].intitule;
            user.email = decodedToken.email;

            localStorage.setItem(this.AGZ_USER, JSON.stringify(user));
            return user;
        }));
  }

  logout() {
    localStorage.removeItem(this.AGZ_USER);
  }

  getPriviledges(): string {
   return this.jwtHelperService.decodeToken(this.currentUserValue().token).privileges;
  }
}
