import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { NotFoundComponent } from './not-found/not-found.component';

// *******************************************************************************
// Layouts

import { Layout2Component } from './layout/layout-2/layout-2.component';
import { Layout2FlexComponent } from './layout/layout-2-flex/layout-2-flex.component';
import { LayoutBlankComponent } from './layout/layout-blank/layout-blank.component';
import { AuthGuard } from './core/guard/auth.guard';
import { CoutureModule } from './feature/couture/couture.module';
import { EcommerceModule } from './feature/ecommerce/ecommerce.module';
import { Layout1Component } from './layout/layout-1/layout-1.component';
import { PaymentComponent } from './shared/components/payment/payment.component';
import { CheckStatusComponent } from './shared/components/payment/check-status/check-status.component';


// *******************************************************************************
// Routes

/* tslint:disable */
const routes: Routes = [
  // Default
  { path: '', redirectTo: 'login', pathMatch: 'full' },

  {
    path: '',
    component: LayoutBlankComponent,
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
  },

  {
    path: 'check-status/:id',
    component: CheckStatusComponent
  },

  {
    path: 'dashboard',
    component: Layout2Component, canActivate: [AuthGuard],
    loadChildren: () => import('./feature/dashboard/dashboard.module').then(m => m.DashboardModule)
  },

  {
    path: 'pay',
    component: PaymentComponent
  },

  {
    path: 'auto',
    component: Layout2Component, canActivate: [AuthGuard],
    loadChildren: () => import('./feature/auto/auto.module').then(m => m.AutoModule)
  },

  {
    path: 'immo',
    component: Layout2Component, canActivate: [AuthGuard],
    loadChildren: () => import('./feature/immo/immo.module').then(m => m.ImmoModule)
  },

  {
    path: 'administration',
    component: Layout2Component, canActivate: [AuthGuard],
    loadChildren: () => import('./feature/administration/administration.module').then(m => m.AdministrationModule)
  },
  {
    path: 'dash',
    component: Layout1Component,
    loadChildren: () => import('./+dashboards/dashboards.module').then(m => m.DashboardsModule)
  },

  // {
  //   path: 'dash', component: Layout2Component, pathMatch: 'full', canActivate: [AuthGuard], children: [
  //     { path: '', component: DashboardComponent },
  //   ]
  // },
  {
    path: 'agence',
    component: Layout2Component, canActivate: [AuthGuard],
    loadChildren: () => import('./feature/agence/agence.module').then(m => m.AgenceModule)
  },

  {
    path: 'couture',
    component: Layout2Component, canActivate: [AuthGuard],
    loadChildren: () => import('./feature/couture/couture.module').then(m => m.CoutureModule)
  },
  {
    path: 'ecommerce',
    component: Layout2Component, canActivate: [AuthGuard],
    loadChildren: () => import('./feature/ecommerce/ecommerce.module').then(m => m.EcommerceModule)
  },
  { path: 'formation', component: Layout2Component, loadChildren: () => import('./feature/formation/formation.module').then(m => m.FormationModule) },
  { path: 'restaurant', component: Layout2Component, loadChildren: () => import('./feature/restaurant/restaurant.module').then(m => m.RestaurantModule) },
  { path: 'evenement', component: Layout2Component, loadChildren: () => import('./feature/evenement/evenement.module').then(m => m.EvenementModule) },
  { path: 'bien-etre', component: Layout2Component, loadChildren: () => import('./feature/bien-etre/bien-etre.module').then(m => m.BienEtreModule) },

  // 404 Not Found page
  { path: '**', component: NotFoundComponent }
];
/* tslint:enable */

// *******************************************************************************
//

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
