import { Injectable } from '@angular/core';
import {environment} from '../../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {TariffCar} from '../model/tariff-car';
import {Car} from '../../car/model/car';
import {TarifImmo} from "../../../immo/model/tarif-immo";
import {House} from "../../../immo/house/model/house";

@Injectable({
  providedIn: 'root'
})
export class TariffService {

  private baseUrl = environment.BASE_URL + 'admin/';

  constructor(private http: HttpClient) { }

  getList(car: Car): Observable<Array<TariffCar>> {
    return this.http.post<Array<TariffCar>>(this.baseUrl + 'listetarifvehicule', car);
  }

  getListCar(car: Car): Observable<Array<TariffCar>> {
    return this.http.post<Array<TariffCar>>(this.baseUrl + 'listetarifvehicule', car);
  }

  getListHouse(house: House): Observable<Array<TarifImmo>> {
    return this.http.post<Array<TarifImmo>>(this.baseUrl + 'listetarifimmo', house);
  }

  /*getById(id: number): Observable<TariffCar> {
    return this.http.get<TariffCar>(this.baseUrl + 'getvehicule/' + id);
  }*/

  create(tariff: TariffCar): Observable<TariffCar> {
    return this.http.post<TariffCar>(this.baseUrl + 'addprixvehicule', tariff);
  }

  update(id: number, tariff: TariffCar): Observable<TariffCar> {
    return this.http.post<TariffCar>(this.baseUrl + 'addprixvehicule', tariff);
  }
}
