<div class="router-transitions py-5">
        <div class="row justify-content-center align-items-center my-2">
            <div class="col-12">
                <p class="text-center">
                    <img [src]="imageSource" style="max-width: 250px; max-height: 300px;" alt="" class="img-fluid">
                </p>
            </div>
            <div class="col-12 my-4">
                <p class="text-center text-muted">{{ status }}</p>
            </div>
        </div>
</div>