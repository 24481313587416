import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ICreateOrderRequest, IPayPalConfig } from 'ngx-paypal';
import { ToastrService } from 'ngx-toastr';
import { AppService } from 'src/app/app.service';
import { Order } from '../../../../feature/dashboard/order/model/order';
import { HttpErrorResponse } from '@angular/common/http';
import { PaymentService } from 'src/app/feature/dashboard/payment/service/payment.service';
import { Payment } from '../../../../feature/dashboard/payment/model/payment';

@Component({
    selector: 'app-paypal-payment',
    templateUrl: './paypal-payment.component.html',
    styles: [
    ]
})
export class PaypalPaymentComponent implements OnInit {
    @Input() command: any;
    @Input() mode: Payment;
    @Output() closeMode = new EventEmitter<boolean>();
    status = true;
    orderId: any;
    payPalConfig?: IPayPalConfig;

    constructor(private appService: AppService, public modal: NgbModal, private toast: ToastrService, private paymentService: PaymentService) {
        this.appService.pageTitle = 'Paiement de Facture';
    }

    ngOnInit(): void {
        console.log("Here the on init", this.mode);
        this.initConfig(this.command, this.mode);
    }

    closeAndCancel() {
        this.closeMode.emit(true);
    }

    initConfig(cmd: any, modePay: Payment): void {
        let total = (cmd.prixtotal / 650).toPrecision(2);
        // let total = '0';
        this.payPalConfig = {
            currency: 'EUR',
            clientId: modePay?.apikey,
            createOrderOnClient: (data) => <ICreateOrderRequest>{
                // console.log("Here is your order", data);
                intent: 'CAPTURE',
                purchase_units: [{
                    amount: {
                        currency_code: 'EUR',
                        value: total,
                        breakdown: {
                            item_total: {
                                currency_code: 'EUR',
                                value: total
                            }
                        }
                    },
                    items: [{
                        name: 'Payement de la commande ' + cmd.idcommande,
                        quantity: '1',
                        category: 'DIGITAL_GOODS',
                        unit_amount: {
                            currency_code: 'EUR',
                            value: total,
                        },
                    }]
                }]
            },
            advanced: {
                commit: 'true'
            },
            style: {
                label: 'paypal',
                layout: 'vertical'
            },
            onApprove: (data, actions) => {
                console.log('onApprove - transaction was approved, but not authorized', data, actions);
                actions.order.get().then(details => {
                    console.log('onApprove - you can get full order details inside onApprove: ', details);

                    this.orderId = details.id;
                    this.toast.info("Traitement en cours ...", "Information");
                    // Save the order id on database
                    this.assignThePayMode(details.id, cmd.idcommande);
                });
            },
            onInit: (data) => {
                console.log("Initialisation", data);
                this.status = false;
            },
            onClientAuthorization: (data) => {
                console.log('onClientAuthorization - you should probably inform your server about completed transaction at this point', data);
                // this.showSuccess = true;
                this.toast.info("Finalisation du processus....", "veuillez patienter");
                this.updateDetails();
            },
            onCancel: (data, actions) => {
                console.log('OnCancel', data, actions);
                // this.showCancel = true;
            },
            onError: err => {
                console.log('OnError', err);
                // this.showError = true;
            },
            onClick: (data, actions) => {
                console.log('onClick', data, actions);
                // this.resetStatus();
            },
        };

        // this.payPalConfig.createOrderOnClient()
    }

    assignThePayMode(orderId, cmdId) {
        this.paymentService.assignPaymentMode(this.command.idcommande, this.mode.idmodepaiement).subscribe(
            res => {
                this.saveOrderId(orderId, cmdId);
            }, (error: HttpErrorResponse) => {
                this.toast.error("Echec d'initiation de paiement", "Erreur");
                this.toast.error(`Message: ${error.message}`, 'Erreur');
            }
        )
    }

    saveOrderId(orderId, cmdId) {
        this.paymentService.savePaypalOrderId(cmdId, orderId).subscribe(
            data => {
                console.log("Data response", data);
            }, (error: HttpErrorResponse) => {
                if (error.status == 0) {
                    this.toast.warning("Veuillez vérifier cotre connexion internet", "Attention");
                } else {
                    this.toast.error("Erreur survenue lors de l'enregistrement de certaines données", "Erreur");
                    this.toast.info("Raffraichissement de la requête !!!", "Veuillez patienter");
                }
                this.saveOrderId(orderId, cmdId);
                // if(confirm('Une erreur est survenue ! '/))
            }
        )
    }

    updateDetails() {
        this.paymentService.changePaymentStatus(this.orderId).subscribe(
            data => {
                console.log("Data response", data);
                location.reload();
            }, (error: HttpErrorResponse) => {
                if (error.status == 0) {
                    this.toast.warning("Veuillez vérifier cotre connexion internet", "Attention");
                } else {
                    this.toast.error("Erreur survenue lors de l'enregistrement de certaines données", "Erreur");
                    this.toast.info("Raffraichissement de la requête !!!", "Veuillez patienter");
                }
                this.updateDetails();
                // if(confirm('Une erreur est survenue ! '/))
            }
        )
    }

}
