import { User } from "src/app/auth/model/user";
import { Service } from "../../administration/service/model/service";
import { Payment } from "../../dashboard/payment/model/payment";
import { House } from "../house/model/house";
import { TarifImmo } from "./tarif-immo";

export class OrderImmo {
    idcommande: number;

    userIduser: User;

    tarifservice: TarifImmo;
    statusvalidation?: string;

    validfrom: string;
    validto: string;
    qty: number;
    prixunitaire: number;
    prixtotal: number;
    status: string;
    villedepart: string = '';
    villearrive: string = '';
    modepaiementid: Payment;
}